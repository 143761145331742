import React, { useState, useEffect, useRef, Suspense } from "react";
import { patterns } from "../../utils/patternOptions";
import Pattern from "../pattern";
const Results = React.lazy(() => import("../results"));
import Spinner from "../spinner";
import { htmlFormattedDate } from "../../utils/dateUtil";
import StyledInput from "../inputs/styledInput";
import StyledDate from "../inputs/styledDate";
import styled from "styled-components";

const Style = styled.div`
  width: 310px;
  text-align: center;
  margin-left: auto;
  margin-right: auto;

  div.holder {
    margin-bottom: 10px;
  }
  div.section-title {
    font-weight: bold;
    margin-bottom: 5px;
    font-size: 1.2rem;
    color: white;
  }

  hr {
    margin-top: 30px;
  }
`;

export default function DripMaths(props) {
  const [deposit, setDeposit] = useState(localStorage.getItem("deposit") ?? "");
  const [claimed, setClaimed] = useState(localStorage.getItem("claimed") ?? "");
  const [price, setPrice] = useState(localStorage.getItem("price") ?? "");
  const [startDate, setStartDate] = useState(
    localStorage.getItem("startDate") ?? ""
  );
  const [pattern, setPattern] = useState(
    localStorage.getItem("maths-pattern")
      ? JSON.parse(localStorage.getItem("maths-pattern"))
      : ["NONE"]
  );

  const floatRegex = /^([0-9]*\.?[0-9]{0,3})$/;

  function setDecimalInputFromEvent(evt, setFunc, sanitiseFunc) {
    const { value } = evt.target;
    if (!value) {
      setFunc("");
    } else if (value.match(floatRegex)) {
      const trimmedValue = value.replace(/^0+(?=[0-9])/, "");

      const afterSanitise = sanitiseFunc
        ? sanitiseFunc(trimmedValue)
        : trimmedValue;

      setFunc(afterSanitise);
    }
  }

  function handleDepositChange(evt) {
    setDecimalInputFromEvent(
      evt,
      (val) => {
        localStorage.setItem("deposit", val);
        setDeposit(val);
      },
      (str) => {
        str = parseFloat(str) > 27397.26 ? "27397.260" : str;
        if (parseFloat(str) === 27397.26) {
          if (parseFloat(claimed) > 100000) {
            setClaimed("100000");
          }
        } else if (parseFloat(claimed) > (str * 3.65).toFixed(3)) {
          setClaimed((str * 3.65).toFixed(3).toString());
        }
        return str;
      }
    );
  }

  function handlePriceChange(evt) {
    setDecimalInputFromEvent(
      evt,
      (val) => {
        localStorage.setItem("price", val);
        setPrice(val);
      },
      (str) => (parseFloat(str) > 10000 ? "10000" : str)
    );
  }

  function handleClaimedChange(evt) {
    setDecimalInputFromEvent(
      evt,
      (val) => {
        localStorage.setItem("claimed", val);
        setClaimed(val);
      },
      (str) => {
        if (!deposit) {
          return "";
        } else if (parseFloat(str) > deposit * 3.65) {
          return deposit == 27397.26
            ? "100000"
            : (deposit * 3.65).toFixed(3).toString();
        } else return str;
      }
    );
  }

  function handleStartDateChange(evt) {
    const { value } = evt.target;
    setStartDate(value);
  }

  if (startDate === "") {
    const d = htmlFormattedDate(new Date());
    localStorage.setItem("startDate", d);
    setStartDate(d);
  }

  return (
    <>
      <Style>
        {/* <div className="section-title">Drip Inputs</div> */}
        <StyledInput
          label="deposit"
          value={deposit}
          onChange={handleDepositChange}
          height={50}
        />
        <StyledInput
          label="claimed"
          value={claimed}
          onChange={handleClaimedChange}
          height={50}
        />
        <StyledInput
          label="price $"
          value={price}
          onChange={handlePriceChange}
          height={50}
        />
        <StyledDate
          label="start"
          value={startDate}
          onChange={handleStartDateChange}
          height={50}
        />

        <hr />
        <div className="section-title">Daily Action Pattern</div>

        <div className="text-input-container">
          <Pattern
            options={patterns}
            selection={pattern}
            addToPattern={(x) => {
              localStorage.setItem(
                "maths-pattern",
                JSON.stringify(pattern.concat(x))
              );
              setPattern(pattern.concat(x));
            }}
            removeFromPattern={(x) => {
              pattern.splice(x, 1);
              const newPattern = [].concat(pattern);
              localStorage.setItem("maths-pattern", JSON.stringify(newPattern));
              setPattern(newPattern);
            }}
          />
        </div>
      </Style>
      {deposit &&
        !isNaN(deposit) &&
        parseFloat(deposit) > 0 &&
        startDate &&
        pattern.length > 0 && (
          <Suspense fallback={<Spinner />}>
            <Results
              price={
                !props.price || isNaN(props.price) ? 0 : parseFloat(props.price)
              }
              tokens={parseFloat(deposit)}
              claimed={!claimed || isNaN(claimed) ? 0 : parseFloat(claimed)}
              startDate={startDate}
              pattern={pattern}
            />
          </Suspense>
        )}
    </>
  );
}
