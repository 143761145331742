import React, { useState, useRef } from "react";
import Modal from "./modal";
import styled from "styled-components";
import { patterns } from "../utils/patternOptions";

const Styles = styled.div`
  div.selection {
    align-items: center;
    display: flex;
    flex-direction: column;
  }
  img.img-add-action {
    /* background-color: white; */
    /* fill: white;
    stroke: white; */
    margin-top: 8px;
    cursor: pointer;
  }
  div.selection-row-index {
    width: 30px;
  }
  div.selection-row {
    font-size: 1.1rem;
    display: flex;
    flex-wrap: wrap;
    padding: 5px;
    width: 260px;
    column-gap: 5px;
    margin: 2px;
    color: black;
    background-color: white;
    border-radius: 10px;
  }

  div.push {
    margin-left: auto;
  }

  div.selection-row-close {
    width: 20px;
    margin-right: 5px;
    cursor: pointer;
  }

  img.bin {
    vertical-align: sub;
  }

  .flex-table {
    display: grid;
    grid-template-columns: repeat(auto-fill, 25%);
    grid-template-rows: 100% auto;
    transition: 0.5s;
    &:hover {
      background: #f5f5f5;
      transition: 500ms;
    }
  }

  .flex-row {
    display: block;
    width: 100%;
    text-align: center;
    padding: 0.5em 0.5em;
  }

  /* The Close Button */
  .close {
    color: #aaa;
    float: right;
    font-size: 28px;
    font-weight: bold;
  }

  .close:hover,
  .close:focus {
    color: black;
    text-decoration: none;
    cursor: pointer;
  }
`;

export default function Pattern(props) {
  const [isOpen, setIsOpen] = useState(false);

  const selectOption = (x) => {
    const { index, value } = x.target;
    props.addToPattern(value);
  };

  let scrollPosition = useRef(0);

  function enable() {
    // console.log("enable");
    scrollPosition.current = window.pageYOffset;
    document.body.style.overflow = "hidden";
    document.body.style.position = "fixed";
    document.body.style.top = `-${scrollPosition.current}px`;
    document.body.style.width = "100%";
    document
      .querySelector('meta[name="theme-color"]')
      .setAttribute("content", "rgba(0, 0, 0, 0.07)");
  }
  function disable() {
    // console.log("disable");
    document.body.style.removeProperty("overflow");
    document.body.style.removeProperty("position");
    document.body.style.removeProperty("top");
    document.body.style.removeProperty("width");
    window.scrollTo(0, scrollPosition.current);
    document
      .querySelector('meta[name="theme-color"]')
      .setAttribute("content", "rgb(54, 54, 54)");
  }

  const toggle = () => {
    const updatedState = !isOpen;
    if (updatedState) {
      enable();
    } else {
      // document.body.style.overflow = 'unset';
      disable();
    }
    setIsOpen(updatedState);
  };

  return (
    <Styles>
      <div className="selection">
        {props.selection.map((x, i) => (
          <div className="selection-row" key={`${i}-option`} value={x}>
            <div className="selection-row-index">#{i + 1}</div>
            <div className="selection-row-label">
              {patterns.find((p) => p.key === x).display}
            </div>
            {props.selection.length > 1 && (
              <div
                className="selection-row-close push"
                onClick={() => props.removeFromPattern(i)}
              >
                <img
                  className="bin"
                  height="26px"
                  width="26px"
                  src="minus.svg"
                  alt=""
                />
              </div>
            )}
          </div>
        ))}
        <div className="add-action" onClick={() => toggle()}>
          <img
            className="img-add-action"
            height="45px"
            width="45px"
            src="plus_1.svg"
            alt=""
          />
        </div>
      </div>
      {isOpen && <Modal setIsOpen={toggle} selectOption={selectOption} />}
    </Styles>
  );
}
