export function formatDate(d) {
  const date = d.getUTCDate();
  const month = formatMonth(d.getUTCMonth() + 1);
  const year = d.getUTCFullYear();
  return `${date} ${month} ${year}`;
}

export function htmlFormattedDate(d) {
  const date = padNumber(d.getDate());
  const month = padNumber(d.getMonth() + 1);
  const year = d.getFullYear();
  return `${year}-${month}-${date}`;
}

export function formatMonth(n) {
  switch (n) {
    case 1:
      return "Jan";
    case 2:
      return "Feb";
    case 3:
      return "Mar";
    case 4:
      return "Apr";
    case 5:
      return "May";
    case 6:
      return "Jun";
    case 7:
      return "Jul";
    case 8:
      return "Aug";
    case 9:
      return "Sep";
    case 10:
      return "Oct";
    case 11:
      return "Nov";
    case 12:
      return "Dec";
  }
}

export function padNumber(n) {
  return n.toString().length === 1 ? "0" + n : n;
}
