import Web3 from "web3/dist/web3.min";
import { numberWithCommas } from "./stringUtils";
import { validWallet } from "./walletUtils";

const rpcURL = "https://bsc-dataseed1.binance.org";
const web3 = new Web3(rpcURL);

const pcs_lp = "0xa0feB3c81A36E885B6608DF7f0ff69dB97491b58";
const pcs_abi = require("./abi/pcs_abi.json");
const pcs_contract = new web3.eth.Contract(pcs_abi, pcs_lp);

const fountain = "0x4fe59adcf621489ced2d674978132a54d432653a";
const fountain_abi = require("./abi/fountain_abi.json");
const fountain_contract = new web3.eth.Contract(fountain_abi, fountain);

const faucet = "0xFFE811714ab35360b67eE195acE7C10D93f89D8C";
const faucet_abi = require("./abi/faucet_abi.json");
const faucet_contract = new web3.eth.Contract(faucet_abi, faucet);

const br34p = "0xa86d305A36cDB815af991834B46aD3d7FbB38523";
const min_abi = require("./abi/min_abi.json");
const br34p_contract = new web3.eth.Contract(min_abi, br34p);

const decimals = 18;

function secondsToDhms(seconds) {
  seconds = Number(seconds);
  var d = Math.floor(seconds / (3600 * 24));
  var h = Math.floor((seconds % (3600 * 24)) / 3600);
  var m = Math.floor((seconds % 3600) / 60);
  var s = Math.floor(seconds % 60);

  var dDisplay = d > 0 ? d + (d == 1 ? " day, " : " days, ") : "";
  var hDisplay = h > 0 ? h + (h == 1 ? " hour, " : " hours, ") : "";
  var mDisplay = m > 0 ? m + (m == 1 ? " minute, " : " minutes, ") : "";
  var sDisplay = s > 0 ? s + (s == 1 ? " second" : " seconds") : "";
  return dDisplay + hDisplay + mDisplay + sDisplay;
}

export const loadWalletInfo = async (walletAddr) => {
  const unclaimed_bal = await faucet_contract.methods
    .claimsAvailable(walletAddr)
    .call();

  const userInfo = await faucet_contract.methods.userInfo(walletAddr).call();

  return {
    walletFound: userInfo.deposits > 0,
    available: parseFloat(unclaimed_bal / 10 ** decimals).toFixed(6),
    deposit: parseFloat(userInfo.deposits / 10 ** decimals).toFixed(3),
    claimed: parseFloat(userInfo.payouts / 10 ** decimals).toFixed(3),
    maxPayout: parseFloat((userInfo.deposits * 3.65) / 10 ** decimals).toFixed(
      3
    ),
    timestamp: new Date().toISOString(),
  };
};

export const loadWalletStats = async (walletAddr) => {
  const users = await faucet_contract.methods.users(walletAddr).call();
  const airdrops = await faucet_contract.methods.airdrops(walletAddr).call();
  // const creditsAndDebits = await faucet_contract.methods
  //   .creditsAndDebits(walletAddr)
  //   .call();
  const unclaimed_bal = await faucet_contract.methods
    .claimsAvailable(walletAddr)
    .call();
  let br34pBalance = await br34p_contract.methods.balanceOf(walletAddr).call();

  // creditsAndDebits - new deposit = credit-debit
  // payoutOf - net_payout = amount paid out
  // deposit - rolls (compounded) - airdrops received

  // const netDeposit = creditsAndDebits._credits - creditsAndDebits._debits;

  const result = {
    walletFound: users.deposits > 0,
    loaded: true,
    buddy: users.upline.toLowerCase(),
    referrals: parseInt(users.referrals),
    totalStructure: parseInt(users.total_structure),
    referralRewards: parseInt(users.match_bonus),
    available: parseFloat(unclaimed_bal / 10 ** decimals),
    deposit: parseFloat(users.deposits / 10 ** decimals),
    maxPayout: parseFloat((users.deposits * 3.65) / 10 ** decimals),
    claimed: parseFloat(users.payouts / 10 ** decimals),
    compounded: parseFloat(users.rolls / 10 ** decimals),
    airdropsSent: parseFloat(airdrops.airdrops / 10 ** decimals),
    airdropsReceived: parseFloat(airdrops.airdrops_received / 10 ** decimals),
    dailyInterest: parseFloat((users.deposits * 0.01) / 10 ** decimals),
    br34pBalance: parseFloat(br34pBalance / 10 ** 8),
    // netDeposit: parseFloat(netDeposit / 10 ** 18),
    // netDepositValue: parseFloat(
    //   (netDeposit + airdrops.airdrops_received + users.rolls - users.payouts) /
    //     10 ** 18
    // ),
    timestamp: new Date().toISOString(),
  };
  const secondsToOneDrip = 86400 / result.dailyInterest;
  result.dripTime = secondsToDhms(secondsToOneDrip);

  return result;
};

export const loadAll = async () => {
  const bnbPrice = await calcBNBPrice();
  const pcs_drip_usd = await pcs_contract.methods.getReserves().call();
  // const fountain_bnb_bal = await fountain_contract.methods.bnbBalance().call();
  // const fountain_drip_bal = await fountain_contract.methods
  //   .tokenBalance()
  //   .call();

  const bnbToSell = web3.utils.toWei("1", "ether");
  const fountain_bnb_token_output_price_wei = await fountain_contract.methods
    .getTokenToBnbInputPrice(bnbToSell)
    .call();
  const fountain_bnb_token_output_price = web3.utils.fromWei(
    fountain_bnb_token_output_price_wei
  );
  // console.log("fountain price: " + parseFloat(bnbOut * bnbPrice).toFixed(3));
  // console.log(
  //   "old fountain price: " +
  //     parseFloat((fountain_bnb_bal * bnbPrice) / fountain_drip_bal).toFixed(3)
  // );

  return {
    bnbPrice: parseFloat(bnbPrice).toFixed(3),
    pcsDripUsdPrice: parseFloat(
      pcs_drip_usd._reserve1 / pcs_drip_usd._reserve0
    ).toFixed(3),
    dexDripBnbPrice: parseFloat(fountain_bnb_token_output_price).toFixed(3),
    dexDripUsdPrice: parseFloat(
      fountain_bnb_token_output_price * bnbPrice
    ).toFixed(3),
    timestamp: new Date().toISOString(),
  };
};

export async function calcBNBPrice() {
  let pancakeSwapContract =
    "0x10ED43C718714eb63d5aA57B78B54704E256024E".toLowerCase();
  const BNBTokenAddress = "0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c"; //BNB
  const USDTokenAddress = "0x55d398326f99059fF775485246999027B3197955"; //USDT

  let pancakeSwapAbi = [
    {
      inputs: [
        { internalType: "uint256", name: "amountIn", type: "uint256" },
        { internalType: "address[]", name: "path", type: "address[]" },
      ],
      name: "getAmountsOut",
      outputs: [
        { internalType: "uint256[]", name: "amounts", type: "uint256[]" },
      ],
      stateMutability: "view",
      type: "function",
    },
  ];
  let bnbToSell = web3.utils.toWei("1", "ether");
  let amountOut;
  try {
    let router = await new web3.eth.Contract(
      pancakeSwapAbi,
      pancakeSwapContract
    );
    amountOut = await router.methods
      .getAmountsOut(bnbToSell, [BNBTokenAddress, USDTokenAddress])
      .call();
    amountOut = web3.utils.fromWei(amountOut[1]);
  } catch (error) {
    console.log(error);
  }
  // console.log(amountOut);
  if (!amountOut) {
    return 0;
  }
  return amountOut;
}
