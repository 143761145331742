import styled from "styled-components";
import React, { useRef } from "react";

const Style = styled.div`
  li.contact {
    color: white;
  }

  img.telegram {
    height: 2rem;
    vertical-align: middle;
    padding-left: 8px;
  }

  a {
    font-size: 2rem;
    color: white;
    text-decoration: none;
    /* text-transform: uppercase; */
  }

  .hamburger {
    display: block;
    cursor: pointer;
    z-index: 1000;
    /* height: 35px;
    width: 35px; */
    /* margin-top: 30px; */
    /* height: 100%;
    width: 100%; */

    /* border-style: solid;
    border-color: white; */
    margin-top: 5px;
    margin-right: 12px;
    height: 30px;
    width: 30px;
    position: relative;
  }
  .hamburger.active .bar:nth-child(2) {
    opacity: 0;
  }
  .hamburger.active .bar:nth-child(1) {
    transform: translateY(8px) rotate(45deg);
  }
  .hamburger.active .bar:nth-child(3) {
    transform: translateY(-8px) rotate(-45deg);
  }

  .bar {
    display: block;
    width: 25px;
    height: 3px;
    margin: 5px auto;
    -webkit-transition: all 0.3s ease-in-out;
    transition: all 0.3s ease-in-out;
    background-color: white;
  }

  .nav-container.active {
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.8);
  }

  .nav-container {
    height: 100%;
    width: 100%;
    top: 0;
    right: 0;
    background-color: rgba(73, 87, 165, 0.226);
    overflow-x: hidden;
    padding: 0;
    margin: 0;
    padding-top: 0.2rem;
  }

  .nav-container {
    display: flex;
    align-items: center;
    position: fixed;
    left: -100%;
    flex-direction: column;

    /* border-radius: 10px; */
    text-align: center;
    box-shadow: 0 10px 27px rgba(0, 0, 0, 0.05);
    transition: none;
    padding: 0;
    margin: 0;
  }

  .nav-item {
    display: inline;
    /* margin-left: 3rem; */
    margin-bottom: 1rem;
    overflow: hidden;
    /* padding: 8px 8px 8px 32px; */
  }
  .nav-item.active {
    margin-left: 0rem;
    margin-bottom: 2rem;
    /* padding: 8px 8px 8px 32px; */
  }

  /* .nav-menu {
    position: block;
  } */

  .nav-menu.active {
    position: fixed;
    width: 100%;
    top: 40%;
    padding: 0;
  }
`;

export default function HamburgerMenu() {
  const [menuActive, setMenuActive] = React.useState(false);

  let scrollPosition = useRef(0);

  function enable() {
    scrollPosition.current = window.pageYOffset;
    document.body.style.overflow = "hidden";
    document.body.style.position = "fixed";
    document.body.style.top = `-${scrollPosition.current}px`;
    document.body.style.width = "100%";
    document
      .querySelector('meta[name="theme-color"]')
      .setAttribute("content", "rgba(0, 0, 0, 0.05)");
  }
  function disable() {
    document.body.style.removeProperty("overflow");
    document.body.style.removeProperty("position");
    document.body.style.removeProperty("top");
    document.body.style.removeProperty("width");
    window.scrollTo(0, scrollPosition.current);
    document
      .querySelector('meta[name="theme-color"]')
      .setAttribute("content", "rgb(54, 54, 54)");
  }

  const toggle = (e) => {
    const updatedState = !menuActive;
    if (updatedState) {
      enable();
    } else {
      // document.body.style.overflow = 'unset';
      disable();
    }
    setMenuActive(updatedState);
  };

  return (
    <Style>
      {/* <div className="menu-options-container"> */}
      <div className={`nav-container${menuActive ? " active" : ""}`}>
        <ul className={`nav-menu${menuActive ? " active" : ""}`}>
          <li className="nav-item contact mono">
            <a
              href="https://t.me/thipss"
              target="_blank"
              rel="noopener noreferrer"
            >
              Contact me
              <img
                className="telegram"
                src="telegram.svg"
                height="2rem"
                alt="telegram"
              />
            </a>
          </li>
          {/* <li className="nav-item">
          <a href="#" className="nav-link" type="charts" onClick={toggle}>
            Charts
          </a>
        </li> */}
        </ul>
      </div>
      {/* <div className="nav-container"> */}
      <div
        className={`hamburger ${menuActive ? " active" : ""}`}
        onClick={toggle}
      >
        <span className="bar" />
        <span className="bar" />
        <span className="bar" />
      </div>
      {/* </div> */}
    </Style>
  );
}
