import styled from "styled-components";
import React, { useState, useEffect, useRef } from "react";
import { loadAll } from "../utils/web3Util";
import { useCancellablePromise } from "../helpers/useCancellablePromise";
import HamburgerMenu from "./hamburgerMenu";

const Style = styled.div`
  header.header {
    /* display: flex; */
    /* flex-direction: column; */
    /* justify-content: space-between; */
    /* padding: 1.2rem 1.5rem; */
  }
  nav {
    display: flex;
    justify-content: space-between;
    width: 100%;
    height: 2rem;
    z-index: 996;
    overflow: unset;
    position: fixed;
    top: 0;
    background-color: rgb(54, 54, 54);
  }

  ul.price-list {
    list-style-type: none;
    margin: 0;
    padding: 0;
    padding-top: 5px;
    padding-left: 10px;
  }

  ul.price-list li {
    /* float: left; */
    display: block;
    color: white;
    text-align: left;
    font-size: 0.85rem;
    /* padding: 16px; */
    margin-left: 16px;
  }

  img.logo-header {
    position: absolute;
    margin-left: 3px;
    margin-top: 6px;
  }
`;

export default function Header(props) {
  return (
    <Style>
      <header className="header">
        <nav>
          <img
            className="logo-header"
            height="17px"
            width="17px"
            src="logo.svg"
            alt=""
          />
          <ul className="price-list">
            {/* <li>BNB ${web3Data.bnbPrice}</li> */}

            <li className="mono">
              PCS/DEX: ${props.pcsDripUsdPrice}/$
              {props.dexDripUsdPrice}
            </li>
            {/* <li>Drip DEX ${web3Data.dexDripUsdPrice}</li> */}
          </ul>
          <HamburgerMenu />
        </nav>
      </header>
    </Style>
  );
}
