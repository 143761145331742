import React, { useState, useEffect } from "react";

import logo from "./logo.svg";
import "./App.css";
import Calculator from "./components/calculator";
// import TextAreaInput from "./components/inputs/styledLabel";

function App() {
  return (
    <div className="App">
      {/* <header className="App-header">
        <img src={logo} className="App-logo" alt="logo" />
      </header> */}

      {/* <TextAreaInput label="Testing" height={55} value="hello world" /> */}
      <Calculator />
    </div>
  );
}

export default App;
