export function toNumbersOnly(str) {
  return str ? str.replace(/\D/g, "") : "";
}

export function toDecimalOnly(str) {
  return str ? str.replace(/[^0-9|\\.]/g, "") : "";
}

export function numberWithCommas(x) {
  return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
}
