import React, { useState, useEffect, useRef, Suspense } from "react";
import styled from "styled-components";
import Header from "./header";
import { loadAll } from "../utils/web3Util";
import { useCancellablePromise } from "../helpers/useCancellablePromise";
import InfoModal from "./infoModal";
import RadioButtons from "./inputs/radioButtons";
import WalletBlock from "./blocks/walletBlock";
import DripMaths from "./blocks/dripMaths";

const Styles = styled.div`
  .logo {
    margin-top: 2rem;
  }

  .input-type-container {
    margin-top: 3px;
    margin-bottom: 5px;
  }

  div.invalid-wallet {
    font-weight: bold;
    color: #f39f9f;
    font-size: 1.1rem;
  }

  button.input-selection {
    /* background-color: white; */
    font-size: 0.9rem;
    padding-left: 15px;
    padding-right: 15px;
    padding-top: 7px;
    padding-bottom: 7px;
    border: none;
    margin: 0;
    cursor: pointer;
    color: black;
    background-color: #f2f2f2;
    width: 80px;
  }
  button.input-selection-active {
    background-color: rgb(144, 179, 249);
    color: white;
    font-weight: bold;

    /* cursor: ; */
  }

  input {
    font-weight: bold;
    font-size: 1.3rem;
    width: 100%;
    text-align: center;
    display: block;
    -webkit-appearance: textfield;
    -moz-appearance: textfield;
    min-height: 1.3em;
  }

  label.fancy-text {
    color: white;
  }

  div.input-container-group {
    padding: 15px;
  }

  div.input-container {
    margin-left: auto;
    margin-right: auto;
    /* width: 300px; */
    /* border: 5px solid #ffff00; */
    text-align: center;
    /* padding: 30px; */
  }

  div.text-input-container {
    margin-left: auto;
    margin-right: auto;
    width: 280px;
    text-align: center;
    /* padding: 15px; */
    /* background-color: rgb(78, 78, 78); */
    border-radius: 20px;
    /* margin-bottom: 10px; */
    /* border: 5px solid #ffff00; */
  }

  .row {
    margin-bottom: 10px;
    position: relative;
    overflow: hidden;
  }

  div.logo-text {
    font-size: 2rem;
    color: rgb(144, 179, 249);
    font-weight: bold;
  }

  b {
    color: white;
    /* color: rgb(144, 179, 249); */
    font-size: 1.1rem;
  }
  hr {
    width: 250px;
    text-align: center;
    /* margin-left: 0; */
  }
`;

export default function Calculator(props) {
  const [activeTab, setActiveTab] = useState(
    localStorage.getItem("activeTab") ?? "DRIP_MATHS"
  );
  const [showWarningModal, setShowWarningModal] = useState(false);
  // const [warned, setWarned] = useState(localStorage.getItem("warned") ?? false);

  const [web3Data, setWeb3Data] = useState({
    bnbPrice: "",
    pcsDripUsdPrice: "",
    dexDripBnbPrice: "",
    dexDripUsdPrice: "",
  });

  const { cancellablePromise } = useCancellablePromise();

  let dripInterval = useRef(false); // holder for timeout id

  const load = async () => {
    try {
      const data = await cancellablePromise(loadAll());
      const newData = Object.assign({}, web3Data, data);
      setWeb3Data(newData);
      console.log(newData);
    } catch (e) {
      if (e.isCanceled) {
        console.log("Promise was canceled");
      } else {
        console.log(e);
      }
    }
  };

  useEffect(() => {
    load();
    dripInterval.current = setInterval(load, 5000);

    return function cleanup() {
      clearInterval(dripInterval.current);
    };
  }, []);

  return (
    <>
      <InfoModal
        isOpen={showWarningModal}
        setIsOpen={(val) => setShowWarningModal(val)}
      />
      <Header
        pcsDripUsdPrice={web3Data.pcsDripUsdPrice}
        dexDripUsdPrice={web3Data.dexDripUsdPrice}
      />
      <Styles>
        <img
          className="logo"
          height="80px"
          width="80px"
          src="logo.svg"
          alt=""
        />
        <div className="logo-text">Dripper tools</div>

        <RadioButtons
          activeTab={activeTab}
          setActiveTab={(evt) => {
            const { value } = evt.target;
            if (
              ["WALLET_MATHS", "WALLET_STATS"].indexOf(value) >= 0 &&
              !localStorage.getItem("warned")
            ) {
              localStorage.setItem("warned", true);
              setShowWarningModal(true);
            }
            setActiveTab(value);
            localStorage.setItem("activeTab", value);
          }}
        />
        <div className="input-container">
          <div className="input-container-group">
            {activeTab === "DRIP_MATHS" && (
              <DripMaths type={activeTab} price={web3Data.dexDripUsdPrice} />
            )}
            {activeTab === "WALLET_MATHS" && (
              <WalletBlock type={activeTab} price={web3Data.dexDripUsdPrice} />
            )}
            {activeTab === "WALLET_STATS" && (
              <WalletBlock type={activeTab} price={web3Data.dexDripUsdPrice} />
            )}
          </div>
        </div>
      </Styles>
    </>
  );
}
