import React, { useState, useEffect, useRef, Suspense } from "react";
import StyledTextarea from "../inputs/styledTextarea";
import { validWallet } from "../../utils/walletUtils";
import { loadAll, loadWalletInfo } from "../../utils/web3Util";
import { patterns } from "../../utils/patternOptions";
import { htmlFormattedDate } from "../../utils/dateUtil";

import Pattern from "../pattern";
const Results = React.lazy(() => import("../results"));

import { useCancellablePromise } from "../../helpers/useCancellablePromise";
import { numberWithCommas } from "../../utils/stringUtils";
import Spinner from "../spinner";

import styled from "styled-components";
import StyledLabel from "../inputs/styledLabel";

const Style = styled.div`
  width: 310px;
  text-align: center;
  margin-left: auto;
  margin-right: auto;

  div.holder {
    margin-bottom: 10px;
  }

  div.section-title {
    font-weight: bold;
    margin-bottom: 5px;
    font-size: 1.2rem;
    color: white;
  }

  hr {
    margin-top: 30px;
  }
`;

export default function WalletMaths(props) {
  const [walletInfo, setWalletInfo] = useState({});
  const [pattern, setPattern] = useState(
    localStorage.getItem("wallet-pattern")
      ? JSON.parse(localStorage.getItem("wallet-pattern"))
      : ["NONE"]
  );

  const { cancellablePromise } = useCancellablePromise();
  let walletInterval = useRef(false);

  useEffect(() => {
    setupLoadWalletInterval(props.walletAddress);

    return function cleanup() {
      clearInterval(walletInterval.current);
    };
  }, []);

  const stopAndClearWalletInfoInterval = () => {
    clearInterval(walletInterval.current);
    setWalletInfo({});
  };

  const setupLoadWalletInterval = (val) => {
    if (validWallet(val)) {
      stopAndClearWalletInfoInterval();
      loadWallet(val);
      walletInterval.current = setInterval(() => loadWallet(val), 5000);
    } else {
      stopAndClearWalletInfoInterval();
    }
  };

  const loadWallet = async (val) => {
    try {
      const data = await cancellablePromise(loadWalletInfo(val));
      setWalletInfo(data);
      console.log(data);
    } catch (e) {
      if (e.isCanceled) {
        console.log("Promise was canceled");
      } else {
        console.log(e);
      }
    }
  };

  return (
    <>
      {validWallet(props.walletAddress) &&
        walletInfo.deposit &&
        walletInfo.deposit > 0 &&
        props.price &&
        walletInfo.claimed &&
        walletInfo.available && (
          <>
            <Style>
              <hr />

              <div className="section-title">My Drip</div>
              <StyledLabel
                label="Available"
                name="label-available"
                height={55}
                value={
                  walletInfo.available +
                  "\n($" +
                  numberWithCommas(
                    (walletInfo.available * props.price).toFixed(2)
                  ) +
                  ")"
                }
                className="styled-label"
              />
              <StyledLabel
                label="Deposit"
                name="label-deposit"
                height={55}
                value={
                  numberWithCommas(walletInfo.deposit) +
                  "\n($" +
                  numberWithCommas(
                    (walletInfo.deposit * props.price).toFixed()
                  ) +
                  ")"
                }
                className="styled-label"
              />
              <StyledLabel
                label="Claimed"
                name="label-claimed"
                height={55}
                value={
                  numberWithCommas(walletInfo.claimed) +
                  "\n($" +
                  numberWithCommas(
                    (walletInfo.claimed * props.price).toFixed()
                  ) +
                  ")"
                }
                className="styled-label"
              />
              <StyledLabel
                label="Price"
                name="label-pricefiat"
                height={40}
                value={"$" + numberWithCommas(props.price)}
                className="styled-label"
              />
              <StyledLabel
                label="Start"
                name="label-start"
                height={40}
                value={new Date().toLocaleDateString()}
                className="styled-label"
              />
              <hr />
              <div className="section-title">Daily Action Pattern</div>

              <div className="text-input-container">
                <Pattern
                  options={patterns}
                  selection={pattern}
                  addToPattern={(x) => {
                    localStorage.setItem(
                      "wallet-pattern",
                      JSON.stringify(pattern.concat(x))
                    );
                    setPattern(pattern.concat(x));
                  }}
                  removeFromPattern={(x) => {
                    pattern.splice(x, 1);
                    const newPattern = [].concat(pattern);
                    localStorage.setItem(
                      "wallet-pattern",
                      JSON.stringify(newPattern)
                    );
                    setPattern(newPattern);
                  }}
                />
              </div>
            </Style>
            {walletInfo.deposit &&
              walletInfo.deposit > 0 &&
              walletInfo.claimed &&
              props.price &&
              pattern.length > 0 && (
                <Suspense fallback={<Spinner />}>
                  <Results
                    price={numberWithCommas(props.price)}
                    tokens={numberWithCommas(walletInfo.deposit)}
                    claimed={numberWithCommas(walletInfo.claimed)}
                    startDate={htmlFormattedDate(new Date())}
                    pattern={pattern}
                  />
                </Suspense>
              )}
          </>
        )}

      {validWallet(props.walletAddress) && !walletInfo.deposit && <Spinner />}

      {validWallet(props.walletAddress) &&
        walletInfo.deposit &&
        walletInfo.deposit == 0 && (
          <div className="invalid-wallet">Wallet not found!</div>
        )}
    </>
  );
}
