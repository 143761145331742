import React, { useState, useEffect, useRef, Suspense } from "react";

import styled from "styled-components";

const Styles = styled.div`
  width: 100%;

  .holder {
    height: ${(props) => props.height + "px"};
    border: 1px;
    border-color: rgba(118, 118, 118, 0.3);
    display: flex;
    margin-right: auto;
    margin-left: auto;
  }

  .header {
    width: 150px;
    height: 100%;
    display: flex;
    align-items: center;
    background: #777f8f;
    /* background: rgba(64, 92, 141, 0.3); */
    border-radius: 10px 0px 0px 10px;
    color: white;
    font-size: 0.75rem;
    font-weight: bold;
    text-transform: uppercase;
    padding-left: 2.5px;
    padding-right: 2.5px;
    letter-spacing: 0.05em;
  }

  .display {
    width: 100%;
    flex-shrink: 1;
    height: 100%;
    display: flex;
    align-items: center;
    border-radius: 0px 10px 10px 0px;
    background: #d5d5d5;
    font-size: 1rem;
    font-weight: bold;
  }

  .label-header {
    width: 100%;
    text-align: center;
  }
  .label-value {
    width: 100%;
    text-align: center;
    overflow-wrap: anywhere;
    padding-left: 10px;
    padding-right: 10px;
  }

  p {
    margin: 0;
  }
`;

export default function StyledLabel(props) {
  return (
    <Styles height={props.height}>
      <div className={`holder ${props.className}`}>
        <div className="header">
          <label className="label-header">
            {props.label.split("\n").map((str, i) => (
              <p key={i}>{str}</p>
            ))}
          </label>
        </div>
        <div className="display">
          <label className="label-value">
            {props.value.split("\n").map((str, i) => (
              <p key={i}>{str}</p>
            ))}
          </label>
        </div>
      </div>
    </Styles>
  );
}
