import React, { useState } from "react";
import styled from "styled-components";
import { patterns } from "../utils/patternOptions";

const Styles = styled.div`
  .darkBG {
    background-color: rgba(0, 0, 0, 0.7);
    width: 100vw;
    height: 100vh;
    z-index: 997;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    position: fixed;
  }

  .centered {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 998;
  }

  .modal {
    width: 250px;
    /* height: 170px; */
    background: white;
    color: white;
    z-index: 999;
    border-radius: 16px;
    box-shadow: 0 5px 20px 0 rgba(0, 0, 0, 0.04);
  }

  .modalHeader {
    height: 50px;
    background: white;
    overflow: hidden;
    border-top-left-radius: 16px;
    border-top-right-radius: 16px;
  }

  .heading {
    margin: 0;
    padding: 10px;
    color: #2c3e50;
    font-weight: 500;
    font-size: 18px;
    text-align: center;
  }

  .modalContent {
    padding: 10px;
    font-size: 14px;
    color: #2c3e50;
    text-align: center;
  }

  .modalActions {
    /* position: absolute; */
    bottom: 2px;
    margin-bottom: 10px;
    width: 100%;
  }

  .actionsContainer {
    display: flex;
    justify-content: space-around;
    align-items: center;
    flex-direction: column;
    padding: 10px;
  }

  .closeBtn {
    cursor: pointer;
    font-weight: 500;
    padding: 4px 8px;
    border-radius: 8px;
    border: none;
    font-size: 18px;
    color: #2c3e50;
    background: white;
    transition: all 0.25s ease;
    box-shadow: 0 5px 20px 0 rgba(0, 0, 0, 0.06);
    position: absolute;
    right: 0;
    top: 0;
    align-self: flex-end;
    margin-top: -7px;
    margin-right: -7px;
  }

  .closeBtn:hover {
    box-shadow: 0 5px 20px 0 rgba(0, 0, 0, 0.04);
    transform: translate(-4px, 4px);
  }

  .deleteBtn {
    margin-top: 10px;
    cursor: pointer;
    font-weight: 500;
    padding: 11px 28px;
    border-radius: 12px;
    font-size: 0.8rem;
    border: none;
    color: #fff;
    background: #ff3e4e;
    transition: all 0.25s ease;
  }

  .deleteBtn:hover {
    box-shadow: 0 10px 20px -10px rgba(255, 62, 78, 0.6);
    transform: translateY(-5px);
    background: #ff3e4e;
  }

  .cancelBtn {
    width: 90%;
    margin-top: 5px;
    margin-bottom: 5px;
    cursor: pointer;
    font-weight: 500;
    padding: 11px 28px;
    border-radius: 12px;
    font-size: 1rem;
    border: none;
    color: #2c3e50;
    background: #fcfcfc;
    transition: all 0.25s ease;
  }

  .cancelBtn:hover {
    box-shadow: none;
    transform: none;
    /* background: #d7d7d7; */
    background: rgba(144, 179, 249, 0.5);
  }
`;

export default function Modal(props) {
  const clickModalSelection = (x) => {
    props.selectOption(x);
    props.setIsOpen();
  };
  return (
    <Styles>
      <div className="darkBG" onClick={() => props.setIsOpen()} />
      <div className="centered">
        <div className="modal">
          {/* <div className="modalHeader">
            <h5 className="heading">Actions</h5>
          </div> */}
          {/* <button className="closeBtn" onClick={() => props.setIsOpen(false)}> */}
          {/* <RiCloseLine style={{ marginBottom: "-3px" }} /> */}
          {/* </button> */}
          {/* <div className="modalContent">
            Are you sure you want to delete the item?
          </div> */}
          <div className="modalActions">
            <div className="actionsContainer">
              {patterns.map((x) => (
                <button
                  key={x.key}
                  value={x.key}
                  className="cancelBtn"
                  onClick={clickModalSelection}
                >
                  {x.display}
                </button>
              ))}
              {/* <button
                className="deleteBtn"
                onClick={() => props.setIsOpen(false)}
              >
                Delete
              </button>
              <button
                className="cancelBtn"
                onClick={() => props.setIsOpen(false)}
              >
                Cancel
              </button> */}
            </div>
          </div>
        </div>
      </div>
    </Styles>
  );
}
