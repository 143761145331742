import React, { useState, useEffect, useRef, Suspense } from "react";
import StyledTextarea from "../inputs/styledTextarea";
import { validWallet } from "../../utils/walletUtils";

import styled from "styled-components";
import WalletMaths from "./walletMaths";
import WalletStats from "./walletStats";

const Style = styled.div`
  width: 310px;
  text-align: center;
  margin-left: auto;
  margin-right: auto;

  div.input-wallet-green textarea {
    background-color: #e5fbe5;
  }

  div.input-wallet-red textarea {
    background-color: #ffdbdb;
  }
`;

export default function WalletBlock(props) {
  const [walletAddress, setWalletAddress] = useState(
    localStorage.getItem("walletAddress") ?? ""
  );

  function handleWalletChange(evt) {
    const { value } = evt.target;
    const sanitisedVal = value.replace(/\n/, "").toLowerCase();

    if (value.length <= 42 && sanitisedVal !== walletAddress) {
      localStorage.setItem("walletAddress", sanitisedVal);
      setWalletAddress(sanitisedVal);
    }
  }

  const walletValidionHelper = (() => {
    if (validWallet(walletAddress)) {
      return "input-wallet-green";
    } else if (walletAddress.length === 42) {
      return "input-wallet-red";
    } else return "";
  })();

  return (
    <>
      <Style>
        <StyledTextarea
          label="Wallet Address"
          name="textarea-wallet-address"
          height={80}
          value={walletAddress}
          className={`styled-textarea ${walletValidionHelper}`}
          onChange={handleWalletChange}
          key="wallet-address"
        />
      </Style>
      {validWallet(walletAddress) &&
        props.price &&
        props.type === "WALLET_MATHS" && (
          <WalletMaths price={props.price} walletAddress={walletAddress} />
        )}

      {validWallet(walletAddress) &&
        props.price &&
        props.type === "WALLET_STATS" && (
          <WalletStats price={props.price} walletAddress={walletAddress} />
        )}
    </>
  );
}
