import React, { useState, useEffect, useRef, Suspense } from "react";
import { validWallet } from "../../utils/walletUtils";
import { loadWalletStats } from "../../utils/web3Util";

import { useCancellablePromise } from "../../helpers/useCancellablePromise";
import { numberWithCommas } from "../../utils/stringUtils";
import Spinner from "../spinner";

import styled from "styled-components";
import StyledLabel from "../inputs/styledLabel";

const Style = styled.div`
  width: 310px;
  text-align: center;
  margin-left: auto;
  margin-right: auto;

  div.section-title {
    font-weight: bold;
    margin-bottom: 5px;
    font-size: 1.2rem;
    color: white;
  }

  div.holder {
    margin-bottom: 10px;
  }
  hr {
    margin-top: 30px;
  }

  pre {
    color: white;
  }
`;

export default function WalletMaths(props) {
  const [walletStats, setWalletStats] = useState({});

  const { cancellablePromise } = useCancellablePromise();
  let walletInterval = useRef(false);

  useEffect(() => {
    setupLoadWalletInterval(props.walletAddress);

    return function cleanup() {
      clearInterval(walletInterval.current);
    };
  }, []);

  const stopAndClearWalletInfoInterval = () => {
    clearInterval(walletInterval.current);
    setWalletStats({});
  };

  const setupLoadWalletInterval = (val) => {
    if (validWallet(val)) {
      stopAndClearWalletInfoInterval();
      loadWallet(val);
      walletInterval.current = setInterval(() => loadWallet(val), 10000);
    } else {
      stopAndClearWalletInfoInterval();
    }
  };

  const loadWallet = async (val) => {
    try {
      const data = await cancellablePromise(loadWalletStats(val));
      setWalletStats(data);
      console.log(data);
    } catch (e) {
      if (e.isCanceled) {
        console.log("Promise was canceled");
      } else {
        console.log(e);
      }
    }
  };

  return (
    <Style>
      {validWallet(props.walletAddress) &&
        walletStats.walletFound &&
        walletStats.loaded &&
        props.price && (
          <>
            <hr />
            {/* <pre>{JSON.stringify(walletStats, null, 2)}</pre> */}
            <div className="section-title">Balances</div>
            <StyledLabel
              label="Available"
              name="label-available"
              height={55}
              value={
                walletStats.available.toFixed(6) +
                "\n($" +
                numberWithCommas(
                  (walletStats.available * props.price).toFixed(2)
                ) +
                ")"
              }
              className="styled-label"
            />
            <StyledLabel
              label="Deposit"
              name="label-deposit"
              height={55}
              value={
                numberWithCommas(walletStats.deposit.toFixed(3)) +
                "\n($" +
                numberWithCommas(
                  (walletStats.deposit * props.price).toFixed()
                ) +
                ")"
              }
              className="styled-label"
            />
            <StyledLabel
              label="Claimed"
              name="label-claimed"
              height={55}
              value={
                numberWithCommas(walletStats.claimed.toFixed(3)) +
                "\n($" +
                numberWithCommas(
                  (walletStats.claimed * props.price).toFixed()
                ) +
                ")"
              }
              className="styled-label"
            />
            <StyledLabel
              label="Compounded"
              name="label-compounded"
              height={55}
              value={
                numberWithCommas(walletStats.compounded.toFixed(3)) +
                "\n($" +
                numberWithCommas(
                  (walletStats.compounded * props.price).toFixed()
                ) +
                ")"
              }
              className="styled-label"
            />
            <StyledLabel
              label="Max Payout"
              name="label-compounded"
              height={55}
              value={
                numberWithCommas(walletStats.maxPayout.toFixed(3)) +
                "\n($" +
                numberWithCommas(
                  (walletStats.maxPayout * props.price).toFixed()
                ) +
                ")"
              }
              className="styled-label"
            />
            <StyledLabel
              label="BR34P Balance"
              name="label-br34p-balance"
              height={40}
              value={numberWithCommas(walletStats.br34pBalance.toFixed(3))}
              className="styled-label"
            />
            {/* {/* <hr /> */}
            <div className="section-title">Direct Deposits</div>{" "}
            {/* <StyledLabel
              label="Net Deposit"
              name="label-ndv"
              height={55}
              value={
                numberWithCommas(walletStats.netDeposit.toFixed(3)) +
                "\n($" +
                numberWithCommas(
                  (walletStats.netDeposit * props.price).toFixed()
                ) +
                ")"
              }
              className="styled-label"
            /> */}
            {/* <StyledLabel
              label="Net Deposit Value"
              name="label-ndv"
              height={55}
              value={
                numberWithCommas(walletStats.netDepositValue.toFixed(3)) +
                "\n($" +
                numberWithCommas(
                  (walletStats.netDepositValue * props.price).toFixed()
                ) +
                ")"
              }
              className="styled-label"
            /> */}
            <hr />
            <div className="section-title">Buddy</div>
            <StyledLabel
              label="Buddy Address"
              name="textarea-wallet-address"
              height={90}
              value={walletStats.buddy}
              key="wallet-address"
            />
            <hr />
            <div className="section-title">Airdrops</div>
            <StyledLabel
              label="Sent"
              name="label-compounded"
              height={55}
              value={
                numberWithCommas(walletStats.airdropsSent.toFixed(3)) +
                "\n($" +
                numberWithCommas(
                  (walletStats.airdropsSent * props.price).toFixed()
                ) +
                ")"
              }
              className="styled-label"
            />
            <StyledLabel
              label="Received"
              name="label-compounded"
              height={55}
              value={
                numberWithCommas(walletStats.airdropsReceived.toFixed(3)) +
                "\n($" +
                numberWithCommas(
                  (walletStats.airdropsReceived * props.price).toFixed()
                ) +
                ")"
              }
              className="styled-label"
            />
            <hr />
            <div className="section-title">Time Stats</div>
            <StyledLabel
              label="Create 1 Drip"
              name="label-compounded"
              height={55}
              value={walletStats.dripTime}
              className="styled-label"
            />
            <StyledLabel
              label="Drip Per Min"
              name="label-compounded"
              height={55}
              value={
                (walletStats.dailyInterest / 24 / 60).toFixed(6) +
                "\n($" +
                ((walletStats.dailyInterest / 24 / 60) * props.price).toFixed(
                  6
                ) +
                ")"
              }
              className="styled-label"
            />
            <StyledLabel
              label="Drip Per Hour"
              name="label-compounded"
              height={55}
              value={
                (walletStats.dailyInterest / 24).toFixed(6) +
                "\n($" +
                ((walletStats.dailyInterest / 24) * props.price).toFixed(6) +
                ")"
              }
              className="styled-label"
            />
            <StyledLabel
              label="Drip Per Day"
              name="label-compounded"
              height={55}
              value={
                walletStats.dailyInterest.toFixed(6) +
                "\n($" +
                (walletStats.dailyInterest * props.price).toFixed(6) +
                ")"
              }
              className="styled-label"
            />
          </>
        )}

      {validWallet(props.walletAddress) &&
        !walletStats.walletFound &&
        !walletStats.loaded && <Spinner />}

      {validWallet(props.walletAddress) &&
        !walletStats.walletFound &&
        walletStats.loaded && (
          <div className="invalid-wallet">Wallet not found!</div>
        )}
    </Style>
  );
}
